<template>
  <div class="DuringVote">
    <hero-animation class="DuringVote__HeroAnimation" />
    <animation-button class="DuringVote__AnimationButton">
      <button class="DuringVote__Button -appearAnime" @click="clickVoteBtn">
        <img
          :src="require(`@images/top/${locale}/btn_vote-text.png`)"
          :alt="$t('vote.006')"
        >
      </button>
    </animation-button>
    <vote-notice class="DuringVote__Notice" />
    <button-a class="DuringVote__ButtonAbout" size="small" @click="$router.push({ name: 'About', params: { lang: locale } })">
      <span v-text="$t('common.002')" />
    </button-a>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import LocaleMixin from '@/mixins/locale'
import ModelModal from '@/models/modal'
import HeroAnimation from '@/components/global/hero-animation.vue'
import AnimationButton from '@/components/global/animation-button.vue'
import ButtonA from '@/components/global/button-a.vue'
import VoteNotice from '@/components/global/vote-notice.vue'
import NaLogin from '@/components/modals/na-login.vue'

export default {
  name: 'DuringVote',
  mixins: [LocaleMixin],
  components: {
    HeroAnimation,
    AnimationButton,
    ButtonA,
    VoteNotice
  },
  computed: {
    ...mapGetters('login', ['isLogin'])
  },
  methods: {
    ...mapActions('modal', { showModal: 'show' }),
    clickVoteBtn () {
      if (this.isLogin) {
        this.$router.push({ name: 'SelectType', params: { lang: this.locale } })
      } else {
        this.showModal(new ModelModal({
          component: NaLogin,
          props: {
            toPath: 'select-type'
          }
        }))
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.DuringVote {
  @at-root {
    & {
    }
    .DuringVote__Button {
      position: relative;
      z-index: 10;
      width: 556px;
      height: 198px;
      margin: 0 auto;
      padding: 0;
      background: url(#{$img-path}top/btn_vote.png) no-repeat center top;
      background-size: auto 100%;
      @include tap-event();

      &.-appearAnime {
        animation: BtnAppear 0.1s ease-in;
      }
    }
    .DuringVote__HeroAnimation {
      margin-top: -50px;
    }
    .DuringVote__AnimationButton {
      margin-top: -90px;
    }
    .DuringVote__Notice {
      margin-top: 20px;
    }
    .DuringVote__ButtonAbout {
      margin: 40px auto 0;
      min-width: 360px;
    }
  }
}

@keyframes BtnAppear {
  from {
    opacity: 0;
    transform: scale(2, 5);
  }
  to {
    opacity: 1;
    transform: scale(1, 1);
  }
}
</style>
