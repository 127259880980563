<template>
  <div class="Top">
    <during-vote v-if="voteOpen && !resultOpen" />
    <ended-vote v-if="!voteOpen && !resultOpen" />
    <result-vote v-if="!voteOpen && resultOpen" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LoaderMixin from '@/mixins/loader'
import DuringVote from '@/components/top/during-vote.vue'
import EndedVote from '@/components/top/ended-vote.vue'
import ResultVote from '@/components/top/result-vote.vue'

export default {
  name: 'Top',
  mixins: [LoaderMixin],
  components: {
    DuringVote,
    EndedVote,
    ResultVote
  },
  computed: {
    ...mapGetters('term', ['voteOpen', 'resultOpen'])
  }
}
</script>
