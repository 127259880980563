<template>
  <button-a
    :tag="'a'"
    :disabled="disabled"
    href="https://fire-emblem-heroes.com/"
    target="_blank"
    rel="noopener noreferrer"
    class="ButtonOfficial"
  >
    <img :src="require(`@images/common/${locale}/txt_to-official.png`)" :alt="$t('common.001')">
  </button-a>
</template>

<script>
import LocaleMixin from '@/mixins/locale'
import ButtonA from '@/components/global/button-a.vue'

export default {
  mixins: [LocaleMixin],
  components: {
    ButtonA
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style lang="scss" scoped>
.ButtonOfficial {
  @at-root {
    & {
      width: 540px;
    }
  }
}
</style>
