<template>
  <section class="AnimationButton">
    <slot />
    <div class="AnimationButton__Particle" :class="{'-start': animationStart}">
      <img
        v-for="item in particle"
        :key="item"
        src="@images/animation-button/ico_particle.png"
        class="AnimationButton__ParticleItem"
        alt=""
      />
    </div>
  </section>
</template>
<script>
export default {
  name: 'AnimationButton',
  computed: {
    particle () {
      const particles = new Array(20)// NOTE computedの必要性を確認
      return particles
    }
  },
  data () {
    return {
      animationStart: false
    }
  },
  mounted () {
    window.setTimeout(this.startAnimation, 2250)
  },
  methods: {
    startAnimation () {
      this.animationStart = true
    }
  }
}
</script>
<style lang="scss" scoped>
.AnimationButton {
  @at-root {
    & {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      height: 350px;
      background-image: url(#{$img-path}animation-button/bg_vote-area.png),
                        url(#{$img-path}animation-button/bg_vote-mask.png);
      background-repeat: no-repeat, no-repeat;
      background-size: 100% auto, 100% auto;
      background-position: 50% 0, 50% 10px;
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1;
        display: block;
        width: 100%;
        height: 417px;
        transform: translate(-50%, -50%);
        opacity: 0;
        background: url(#{$img-path}animation-button/btn_vote-blink.png) no-repeat;
        background-size: auto 100%;
        animation: blink 2.8s ease-in-out infinite;
        pointer-events: none;
      }
    }
    .AnimationButton__Particle {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      visibility: hidden;
      pointer-events: none;
      &.-start {
        visibility: visible;
      }
    }
    .AnimationButton__ParticleItem {
      position: absolute;
      top: -100px;
      left: 0;
      z-index: 3;

      @for $i from 1 through 20 {
        &:nth-child(#{$i}){
          animation: particle-animation-#{$i} random(5000) + 2500ms infinite;
          animation-delay: random() * 100ms;
          transform: translate(306px, 248px);
          opacity: 0;
        }
      }
    }
  }
}

@keyframes blink {
  75% {
    opacity: 1;
  }
}

@for $i from 1 through 20 {
  @keyframes particle-animation-#{$i} {
    10% {
      opacity: 1;
    }
    30% {
      transform: translate((random(710) - 54px), (random(450) + 38px));
      opacity: 0;
    }
  }
}
</style>
