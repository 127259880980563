<template>
  <div class="VoteNotice">
    <box-gold class="VoteNotice__Inner">
      <p class="VoteNotice__Details">
        <span v-html="replaceText($t('top.002'), {samelist_url : `/${locale}/character`})" /><br><br>
        <span v-html="$t('top.003')" />
      </p>
    </box-gold>
  </div>
</template>
<script>
import LocaleMixin from '@/mixins/locale'
import replaceTextMixin from '@/mixins/replace-text'
import BoxGold from '@/components/global/box-gold.vue'

export default {
  name: 'VoteNotice',
  mixins: [LocaleMixin, replaceTextMixin],
  components: {
    BoxGold
  }
}
</script>
<style lang="scss" scoped>
.VoteNotice {
  @at-root {
    & {
    }
    .VoteNotice__Inner {
      margin-left: auto;
      margin-right: auto;
    }
    .VoteNotice__Details {
      white-space: pre-wrap;
    }
  }
}
</style>
