<template>
  <div class="HeroAnimation">
    <loader class="HeroAnimation__Loading" :class="{'-loading': isLoading}" />
    <div class="HeroAnimation__List" :class="{'-loading': isLoading}">
      <div class="HeroAnimation__ListItem">
        <div class="HeroAnimation__HeroImage" v-for="(item,i) in heroImagesList(0)" :key="i">
          <img :src="heroImage(item)" :alt="item">
        </div>
      </div>
      <div class="HeroAnimation__ListItem">
        <div class="HeroAnimation__HeroImage" v-for="(item,i) in heroImagesList(4)" :key="i">
          <img :src="heroImage(item)" :alt="item">
        </div>
      </div>
      <div class="HeroAnimation__ListItem">
        <div class="HeroAnimation__HeroImage" v-for="(item,i) in heroImagesList(8)" :key="i">
          <img :src="heroImage(item)" :alt="item">
        </div>
      </div>
      <div class="HeroAnimation__ListItem">
        <div class="HeroAnimation__HeroImage" v-for="(item,i) in heroImagesList(12)" :key="i">
          <img :src="heroImage(item)" :alt="item">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loader from '@/components/global/loader.vue'

export default {
  name: 'heroAnimation',
  components: {
    Loader
  },
  data () {
    return {
      heroes: [],
      isLoading: true
    }
  },
  mounted () {
    this.createHeroArray()
    window.setTimeout(this.finishLoading, 1000)
  },
  methods: {
    finishLoading () {
      this.isLoading = false
    },
    createHeroArray () {
      const heroes = []
      for (let i = 0; i < 40; i++) {
        heroes.push(`top_${i + 1}.png`)
      }
      // アニメーションの画像が毎回変わるように配列をシャッフル
      for (let i = heroes.length - 1; i > 0; i--) {
        const r = Math.floor(Math.random() * (i + 1))
        const tmp = heroes[i]
        heroes[i] = heroes[r]
        heroes[r] = tmp
      }
      this.heroes = heroes
    },
    heroImagesList (startNum) {
      const start = startNum || 0
      return this.heroes.filter((h, i) => i >= start && i < start + 4)
    },
    heroImage (imageName) {
      return require(`@images/hero-animation/heroes/${imageName}`)
    }
  }
}
</script>
<style lang="scss" scoped>
.HeroAnimation {
  @at-root {
    & {
      position: relative;
      width: 100%;
      height: 488px;
    }
    .HeroAnimation__Loading {
      visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      background-repeat: no-repeat;
      background-size: 410px auto;
      background-position: 50% 50%;
      &.-loading {
        visibility: visible;
      }
      > img {
        animation: spin 2s linear infinite forwards;
      }
    }
    .HeroAnimation__List {
      display: flex;
      width: 100%;
      height: 100%;
      &.-loading {
        visibility: hidden;
      }
    }
    .HeroAnimation__ListItem {
      position: relative;
      height: 100%;
      &:nth-child(1),
      &:nth-child(4){
        width: 154px;
      }
      &:nth-child(2) {
        width: 165px;
        padding-left: 8px;
        background: linear-gradient(to right, rgba(0,0,0,.3) 0%,rgba(0,0,0,.3) 5%,rgba(0,0,0,0) 5%,rgba(0,0,0,0) 97.5%,rgba(0,0,0,.3) 97.5%,rgba(0,0,0,.3) 100%);
        // FIXME 子要素を作らない
        > .HeroAnimation__HeroImage {
          left: 8px;
        }
      }
      &:nth-child(3) {
        width: 167px;
        padding-right: 8px;
        background: linear-gradient(to right, rgba(0,0,0,.3) 0%,rgba(0,0,0,.3) 3%,rgba(82, 63, 63, 0) 3%,rgba(0,0,0,0) 95%,rgba(0,0,0,.3) 95%,rgba(0,0,0,.3) 100%);
        // FIXME 子要素を作らない
        > .HeroAnimation__HeroImage {
          right: 8px;
        }
      }
    }
    .HeroAnimation__HeroImage {
      position: absolute;
      top: 0;
      opacity: 0;
      animation: hero .25s linear backwards;
      &:nth-child(1){
        animation-delay: 1s;
      }
      &:nth-child(2){
        animation-delay: 1.25s;
      }
      &:nth-child(3){
        animation-delay: 1.5s;
      }
      &:nth-child(4){
        animation-delay: 1.75s;
        animation-fill-mode: forwards;
      }
      img {
        width: 154px;
      }
    }
  }
}

@keyframes hero {
  from {
    opacity: 0;
    transform: scale(2, 2);
  }
  to {
    opacity: 1;
    transform: scale(1, 1);
  }
}
</style>
