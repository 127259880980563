<template>
  <div class="EndedVote">
    <div class="EndedVote__Inner">
      <box-blue :isHalfWidth="true" class="EndedVote__Info EndedVoteInfo">
        <div class="EndedVoteInfo__Inner">
          <img class="EndedVoteInfo__Image" src="@images/top/ended-hero.png" alt="">
          <div class="EndedVoteInfo__Text">
            <p v-html="$t('top.ended-001')" />
            <p v-html="$t('top.ended-002')" />
            <p v-html="$t('top.ended-003')" />
          </div>
        </div>
      </box-blue>
    </div>
    <button-official class="EndedVote__ButtonOfficial" />
  </div>
</template>

<script>
import BoxBlue from '@/components/global/box-blue.vue'
import ButtonOfficial from '@/components/global/button-official.vue'

export default {
  name: 'EndedVote',
  components: {
    BoxBlue,
    ButtonOfficial
  }
}
</script>
<style lang="scss" scoped>
.EndedVote {
  @at-root {
    & {
      padding-top: 5px;
    }
    .EndedVote__Inner {
      position: relative;
      margin-top: 10px;
    }
    // NOTE: override generic components
    .EndedVote__Info {
      margin: 10px 50px 0 auto;
    }
    .EndedVote__ButtonOfficial {
      margin: 75px auto 0;
    }
  }
}

.EndedVoteInfo {
  @at-root {
    & {
      display: flex;// wrapper for IE11
      justify-content: center;
    }
    .EndedVoteInfo__Inner {
      display: flex;
      align-items: center;
      min-height: 360px;
    }
    .EndedVoteInfo__Image {
      position: absolute;
      top: -10px;
      left: 0;
      z-index: 3;
      width: 295px;
    }
    .EndedVoteInfo__Text {
      position: relative;
      z-index: 4;
      width: 100%;
      margin: 0 auto;
      text-align: center;
      font-size: $fs-2large;
      line-height: 38px;
      text-shadow: $ts-base;
      > p {
        margin: 40px -20px 0;
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
}
</style>
