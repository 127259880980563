<template>
  <div class="ResultVote">
    <div class="ResultVote__MainImages">
      <img src="@images/top/result-hero.png" alt="">
    </div>
    <animation-button class="ResultVote__AnimationButton">
      <button class="ResultVote__Button -appearAnime" @click="resultBtnClick()">
        <img :src="require(`@images/top/${locale}/btn_result-text.png`)" :alt="$t('result.001')">
        <span class="ResultVote__ButtonOrnaments">
          <span v-for="item in ornament" :key="item" class="ResultVote__ButtonOrnament" />
        </span>
      </button>
    </animation-button>
    <button-official class="ResultVote__ButtonOfficial" />
  </div>
</template>
<script>
import LocaleMixin from '@/mixins/locale'
import AnimationButton from '@/components/global/animation-button.vue'
import ButtonOfficial from '@/components/global/button-official.vue'

export default {
  name: 'ResultVote',
  mixins: [LocaleMixin],
  components: {
    AnimationButton,
    ButtonOfficial
  },
  methods: {
    resultBtnClick () {
      this.$router.push({ name: 'Result', params: { lang: this.locale } })
    }
  },
  computed: {
    ornament () {
      const ornaments = new Array(10)
      return ornaments
    }
  }
}
</script>
<style lang="scss" scoped>
.ResultVote {
  @at-root {
    & {
    }
    .ResultVote__MainImages {
      margin-top: -20px;
    }
    .ResultVote__Button {
      @include tap-event();
      position: relative;
      z-index: 10;
      display: block;
      width: 556px;
      height: 198px;
      margin: 0 auto;
      padding: 0;
      background: url(#{$img-path}top/btn_result.png) no-repeat center top;
      &.-appearAnime {
        animation: BtnAppear 0.1s ease-in;
      }
    }
    .ResultVote__ButtonOrnaments {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
    }
    .ResultVote__ButtonOrnament {
      position: absolute;
      z-index: 100;
      display: block;
      width: 40px;
      height: 40px;
      opacity: 0;
      background: url(#{$img-path}common/glitter.png) no-repeat;
      @for $i from 1 through 10 {
        &:nth-child(#{$i}) {
          top: random(160) + px;
          left: random(580) + px;
          animation: Glitter .6s random(20) * 0.1 + s ease-out both infinite alternate;
        }
      }
    }
    .ResultVote__AnimationButton {
      margin-top: -90px;
    }
    .ResultVote__ButtonOfficial {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

@keyframes BtnAppear {
  from {
    opacity: 0;
    transform: scale(2, 5);
  }
  to {
    opacity: 1;
    transform: scale(1, 1);
  }
}
@keyframes Glitter {
  0% {
    opacity: 0;
    transform: scale(.1);
  }
  100% {
      opacity: 1;
      transform: scale(1.2);
  }
}
</style>
